import { Col, Spacer, View } from "@amzn/stencil-react-components/layout";
import { H4, Text } from "@amzn/stencil-react-components/text";
import React, { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { OrderedList } from "@/components/Commons/OrderedList";
import { StepBodyStyledText } from "@/components/Commons/StepBodyStyledText";
import { ITS_LIGHT_TEAL } from "@/components/constants";
import { getOS, OPERATING_SYSTEM } from "@/helpers/os-helper";
import { useUserNotify } from "@/hooks/useUserNotify";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";

/**
 * Show a list of instructions for completing the post-login setup on day-1.
 */
function PostLoginInstructions(): JSX.Element {
  const isMac = getOS() === OPERATING_SYSTEM.MAC;

  if (isMac) {
    return (
      <OrderedList
        formatMessagesListProps={[
          { id: "allStepsCompleted.mac.postLoginStep1" },
          { id: "allStepsCompleted.mac.postLoginStep2" },
        ]}
        dataTestId="postLoginMacSteps"
      />
    );
  } else {
    return (
      <>
        <OrderedList
          formatMessagesListProps={[
            { id: "allStepsCompleted.windows.postLoginStep1" },
            { id: "allStepsCompleted.windows.postLoginStep2" },
            { id: "allStepsCompleted.windows.postLoginStep3" },
          ]}
          dataTestId="postLoginWinsSteps"
        />
        <Text
          fontWeight="medium"
          dataTestId="allStepsCompletedWindowsLogoutTitle"
        >
          <FormattedMessage id="allStepsCompleted.windows.howToLogoutTitle" />
        </Text>
        <OrderedList
          formatMessagesListProps={[
            { id: "allStepsCompleted.windows.postLoginStep4" },
            { id: "allStepsCompleted.windows.postLoginStep5" },
            { id: "allStepsCompleted.windows.postLoginStep6" },
          ]}
          dataTestId="postLoginWinsLogout"
        />
      </>
    );
  }
}

/**
 * Display a view to inform the NHs that they have completed all steps required to on board with the pre-login
 * experience.
 */
export default function AllStepsCompleted(): JSX.Element {
  const { dispatch } = useContext(AppContext);

  /** Inform NHs about the post-login instructions */
  useUserNotify();

  /**
   * Set the refPage to provide context to IT Support should the new hire initiate
   * a chat with support
   */
  useEffect(() => {
    dispatch({
      type: ActionType.SET_CHAT_SUPPORT_REF_PAGE,
      chatSupportRefPage: "ezo-complete",
    });
  }, []);

  return (
    <View
      backgroundColor={ITS_LIGHT_TEAL}
      display="flex"
      justifyContent="start"
      alignItems="start"
      padding={{ top: "S400", bottom: "S700", left: "S700", right: "S700" }}
      dataTestId="allStepsCompletedView"
    >
      <Spacer width="S700" />
      <Col gridGap="S300">
        <H4
          color="green90"
          fontWeight="medium"
          dataTestId="allStepsCompletedTitle"
        >
          <FormattedMessage id="allStepsCompleted.title" />
        </H4>
        <StepBodyStyledText>
          <Text fontWeight="medium" dataTestId="allStepsCompletedNextStep">
            <FormattedMessage id="allStepsCompleted.nextTo" />
          </Text>
          <PostLoginInstructions />
        </StepBodyStyledText>
      </Col>
    </View>
  );
}
