/* istanbul ignore file */
// Since this component is more or less just initialization code and then loads an external library and we can't interact in jest with the widget that was loaded, don't provide coverage for this file.
import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";
import { v4 as uuid, validate as isValidUUID } from "uuid";

import { Logger } from "@/apis/Logger";
import { CHAT_WIDGET_DEFAULT_INITIALIZATION_PARAMETERS } from "@/components/GetSupport/ChatWidget/constants";
import { getHelpMsgLocaleIdByRefPage } from "@/components/GetSupport/ChatWidget/helper";
import {
  ChatWidgetInitializationParameters,
  ITSCustomerWidget,
} from "@/components/GetSupport/ChatWidget/types/chat-widget";
import { ChatSupportContextReason } from "@/components/GetSupport/ChatWithITSupport/constants";
import {
  RefLinkValue,
  RefPageValue,
} from "@/components/GetSupport/ChatWithITSupport/types";
import { getOS, OPERATING_SYSTEM } from "@/helpers/os-helper";
import { getStageConfig } from "@/helpers/stageConfig";

/**
 * Creates a Chat Widget.
 * @see https://code.amazon.com/packages/ITSupportConnectCustomerClient/trees/mainline
 * @see https://code.amazon.com/packages/FirstAidHelpdeskStaticWebsite/blobs/mainline/--/src/view/Page/ChatWidget/index.tsx
 */
export function ChatWidget({
  setVisibilityHandler,
  refPage,
  refLink,
  username,
  shouldInitialize,
}: {
  setVisibilityHandler: (isVisible: boolean) => void;
  refPage?: RefPageValue;
  refLink?: RefLinkValue;
  nonDirectedSupportCtx?: ChatSupportContextReason;
  username?: string;
  shouldInitialize?: boolean;
}): JSX.Element {
  const intl = useIntl();
  const os = getOS() === OPERATING_SYSTEM.MAC ? "mac" : "win";

  // When the chat close button is clicked, close the flyout.
  const closeChatButtonHandler = (): void => {
    setVisibilityHandler(false);
  };

  // Get the help message intl ID associated with the ref page.
  const helpMessageIntlIdFromRefPage = getHelpMsgLocaleIdByRefPage(refPage);

  /**
   * PersonID must be in a valid UUID format.
   * @see https://tiny.amazon.com/1et196yac/codeamazpackITSublob7939open
   */
  const personId = isValidUUID(Logger.getSessionId())
    ? Logger.getSessionId()
    : uuid();

  // Set chat widget initial parameters. See ChatViewInitializationParameters docs for definitions of these properties.
  const initParams: ChatWidgetInitializationParameters = {
    ...CHAT_WIDGET_DEFAULT_INITIALIZATION_PARAMETERS,
    closeChatButtonHandler,
    msg: helpMessageIntlIdFromRefPage
      ? intl.formatMessage({ id: helpMessageIntlIdFromRefPage })
      : "No message",
    displayName: username ?? "UnauthenticatedEZOUser",
    username:
      /* Start chat API input requires a specific pattern for validating the username. Using the testing accounts, the username given breaks this (it's like `ezo_newhire_09`),
       so check if the username matches a valid Amazon username pattern OTHERWISE return a default username that is specifically validated.
       The `displayName` property is not authenticated like that and also has the username given anyways.
       @see https://code.amazon.com/packages/ITSupportConnectApiModel/blobs/793971fc511ca0152ee31d5ebec01d4c598f8691/--/open-api-definition.yml#L1085-L1090
     */
      username && username.match(/^[a-z]+$/)
        ? username
        : CHAT_WIDGET_DEFAULT_INITIALIZATION_PARAMETERS.username,
    personId,
    refOs: os,
    midwayAuth: false,
  };

  // Set the refPage if available
  if (refPage) {
    initParams.refPage = refPage;
  }

  if (refLink) {
    // @see https://code.amazon.com/packages/ITSupportConnectApiModel/blobs/793971fc511ca0152ee31d5ebec01d4c598f8691/--/open-api-definition.yml#L1106 Do not pass uninitialized values since they will not be omitted and will fail initialization then.
    initParams["refLink"] = refLink;
  }

  // Inject the scripts into the page on component first load.
  useEffect(() => {
    // Array because we might need to load more scripts in the future, ex: to include the feedback widget.
    for (const sourceUrl of [getStageConfig().chatWidgetClientSrc]) {
      // Check if we already loaded this once, like if the user opens and closes the flyout which renders this.
      if (!document.head.querySelector(`script[id="${sourceUrl}-script"]`)) {
        const script = document.createElement("script");
        script.id = `${sourceUrl}-script`;
        script.async = true;
        script.src = sourceUrl;
        script.type = "text/javascript";
        /*
         Appending to head results in the script being immediately executed. In the case of the chat client js,
         will cause custom elements to be added to the customElements register.
        */
        document.head.appendChild(script);
      }
    }
  }, []);

  // Ensure this loads once and keeps this value between reloads. Otherwise it causes new messages to append into the chat client on every rerender.
  useMemo(() => {
    if (shouldInitialize) {
      /*
      customElements is a global method which holds a registry of custom HTML elements.
      When the script is loaded in the useEffect hook, it initializes a custom widget in the registry <its-customer-widget />.
      When this widget is registered, find the internal element "chat-widget" and call `initialize` on the object.
     */
      void customElements.whenDefined("its-customer-widget").then(() => {
        const chatWidget = document.getElementById(
          "chat-widget"
        ) as ITSCustomerWidget;

        // @see https://code.amazon.com/packages/ITSupportConnectCustomerClient/blobs/8fdd0a03fcbaf5a0466437597af06629a439ea37/--/src/controllers/chat-controller.ts#L59
        chatWidget?.initialize(initParams);
        return;
      });
    }
  }, [ChatWidget, shouldInitialize]);

  return (
    <div
      className="chat-widget-div"
      data-role="page"
      data-test-id="chatWidgetTestId"
    >
      <its-customer-widget
        id="chat-widget"
        data-test-id="chatWidgetClientTestId"
      ></its-customer-widget>
    </div>
  );
}
